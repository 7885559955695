import React, { ReactNode, useEffect } from 'react'
import Link from 'next/link'
import { Field, Form } from 'react-final-form'
import { validate } from 'validate.js'

import { Button, PasswordInput } from '@mc/design-system'
import { Trans, useTranslation } from '@mc/i18n'

import { Terms } from '../../components/Terms'
import { i18nInstance } from '../../i18n/i18nInstance'
import { FormError } from '../../components/FormError'
import { useAuthEvents } from '../../hooks/tracking'
import { paths } from '../../utils/paths'
import { useCtaPreserveQueryParams } from '../../utils/cta'
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from '../../utils/validations'

type FormValues = {
  password?: string
}

const tf = i18nInstance.getFixedT(null, '@mc/persona')

const constraints = {
  password: {
    presence: {
      message: tf('auth.common.password.required'),
    },
    length: {
      minimum: MIN_PASSWORD_LENGTH,
      tooShort: tf('auth.common.password.tooShort'),
      maximum: MAX_PASSWORD_LENGTH,
      tooLong: tf('auth.common.password.tooLong'),
    },
  },
}

interface PasswordStepProps {
  onPasswordSubmit: (values: FormValues) => void
  onClickBack: () => void
  isLoading?: boolean
  error?: string | ReactNode
  email: string
}

export const PasswordStep = ({
  onPasswordSubmit,
  onClickBack,
  isLoading,
  error,
  email,
}: PasswordStepProps) => {
  const { t } = useTranslation('@mc/persona')
  const getUrlWithQueryParams = useCtaPreserveQueryParams()
  const { trackPageLoadPasswordStep, trackSignClick } = useAuthEvents({
    step: 2,
  })

  useEffect(() => {
    trackPageLoadPasswordStep()
  }, [trackPageLoadPasswordStep])

  const handleResetPasswordClick = () => {
    trackSignClick({
      cta: 'reset password',
      userType: 'existing user',
    })
  }

  const handleFormSubmit = ({ password }: FormValues) => {
    if (password) {
      onPasswordSubmit({ password })
      trackSignClick({
        cta: 'continue',
        userType: 'existing user',
      })
    }
  }

  return (
    <>
      <h1 className='mc-text-h2 mc-mb-2'>{t('auth.password.title')}</h1>
      <p className=' mc-mb-8 mc-text-color--medium'>
        <Trans
          ns='@mc/persona'
          i18nKey='auth.password.description'
          values={{ email }}
        >
          <span className='mc-text--bold' style={{ wordBreak: 'break-all' }} />
          <Button
            style={{ textTransform: 'lowercase' }}
            className='mc-p-0 mc-text--normal'
            kind='link'
            onClick={onClickBack}
          />
        </Trans>
      </p>
      <Form
        onSubmit={handleFormSubmit}
        validate={(data) =>
          validate(data, constraints, { fullMessages: false })
        }
        render={({ handleSubmit, values, pristine, valid }) => (
          <form onSubmit={handleSubmit}>
            <Field
              component={PasswordInput}
              onTogglePassword={() => {}}
              isDarkMode
              type='password'
              required
              id='password'
              name='password'
              placeholder={t('auth.common.password.placeholder')}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />

            <Terms className='mc-mt-8' />

            {error && (
              <FormError
                error={error || t('auth.login.defaultError')}
                className='mc-mt-8'
              />
            )}

            <Button
              type='submit'
              className='mc-my-8 mc-corners--11'
              disabled={!values.password || isLoading || pristine || !valid}
              kind='primary'
              fullWidth
            >
              {t('auth.common.cta')}
            </Button>
          </form>
        )}
      />
      <div className='flex row no-gutters mc-w-100 justify-content-center'>
        <Link
          className='mc-text--link mc-text--normal'
          href={getUrlWithQueryParams(
            paths.personaCustomAuth('forgot-password'),
          )}
          onClick={handleResetPasswordClick}
        >
          {t('auth.password.resetPassword')}
        </Link>
      </div>
    </>
  )
}
