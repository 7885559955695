import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { useTranslation } from '@mc/i18n'
import { IconCloseDefault } from '@mc/design-system'

import { BoxContainer } from '../../components/BoxContainer'
import { Auth0Service } from '../../services/Auth0Service'
import { useCheckEmail } from '../../hooks/auth'
import { useCheckoutData } from '../../components/CheckoutProvider/CheckoutProvider'
import { paths } from '../../utils/paths'
import { NavBar } from '../../components'
import { useAuthEvents } from '../../hooks/tracking'
import { useCtaPreserveQueryParams } from '../../utils/cta'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'
import {
  MAX_EMAIL_LENGTH,
  MAX_EMAIL_LOCAL_PART_LENGTH,
} from '../../utils/validations'

import { PasswordStep } from './PasswordStep'
import { EmailStep } from './EmailStep'

export const LoginSteps = ({ errorMessage }: { errorMessage?: string }) => {
  const { t } = useTranslation('@mc/persona')
  const getUrlWithQueryParams = useCtaPreserveQueryParams()

  const {
    mutate: handleLogin,
    isLoading,
    error: err,
    isSuccess,
    reset,
  } = useMutation(Auth0Service.login, {
    onSuccess: ({ redirect }: { redirect: string }) => {
      window.location.href = redirect
    },
  })
  const loginError = err as { message: string }

  const { query, push, back } = useRouter()
  const { email, setEmail } = useCheckoutData()
  const comesFromSignup = query.from === 'signup' && email
  const isSocial = comesFromSignup && query.social === 'true'
  const [step, setStep] = useState<'email' | 'password'>(
    comesFromSignup && !isSocial ? 'password' : 'email',
  )
  const [emailError, setEmailError] = useState<string | undefined>(undefined)

  const { page } = useAuthEvents({ step: step === 'email' ? 1 : 2 })
  const { trackGoldStandardPageViewed, trackCartEmailCaptured } =
    useNonCommerceGoldStandardEvents('login')

  useEffect(() => {
    trackGoldStandardPageViewed()
  }, [trackGoldStandardPageViewed])

  const handleSetPasswordStep = () => {
    setStep('password')
  }

  const handlePasswordSubmit = ({ password }: { password?: string }) => {
    if (password && email) {
      handleLogin({
        email,
        password,
        returnTo: encodeURIComponent(window.location.href),
      })
    }
  }

  const {
    checkEmail,
    checkEmailMutation,
    isLoadingCheckEmail,
    resetCheckEmail,
  } = useCheckEmail({
    onSuccess: (res) => {
      if (
        res.identityProviders?.length === 1 &&
        res.identityProviders?.includes('email')
      ) {
        handleSetPasswordStep()
      }
    },
    onError: (error: { status: number }) => {
      if (error?.status === 404) {
        trackCartEmailCaptured({ email })
        push(getUrlWithQueryParams(paths.personaCheckoutMembership))
      } else if (error?.status === 400) {
        setEmailError(
          t('auth.login.emailFormatError', {
            maxLength: MAX_EMAIL_LENGTH,
            maxLocal: MAX_EMAIL_LOCAL_PART_LENGTH,
          }),
        )
      }
    },
  })

  const handleEmailSubmit = ({ email: emailSubmit }: { email?: string }) => {
    if (emailSubmit) {
      setEmailError(undefined)
      if (emailSubmit !== email || email) {
        checkEmailMutation({ email: emailSubmit })
      } else if (checkEmail?.identityProviders?.includes('email')) {
        handleSetPasswordStep()
      }
      setEmail(emailSubmit)
    }
  }

  const handleClickBack = () => {
    resetCheckEmail()
    reset()
    setStep('email')
  }

  return (
    <>
      <div className='d-flex d-md-none row no-gutters mc-p-6 align-items-center justify-content-end'>
        <button className='mc-clickable' onClick={back}>
          <IconCloseDefault
            size='sm'
            style={{ color: 'var(--mc-color-icon-tint-dark)' }}
          />
        </button>
      </div>
      <NavBar pageName={page} showLogin={false} source='login' />
      <BoxContainer>
        {step === 'email' && (
          <EmailStep
            initialValues={{ email }}
            onEmailSubmit={handleEmailSubmit}
            onChangeEmail={() => {
              setEmailError(undefined)
            }}
            providers={checkEmail?.identityProviders}
            isLoading={!!email && isLoadingCheckEmail}
            showErrorBox={true}
            emailError={emailError}
            errorMessage={errorMessage}
          />
        )}
        {step === 'password' && (
          <PasswordStep
            onPasswordSubmit={handlePasswordSubmit}
            email={email}
            onClickBack={handleClickBack}
            isLoading={isLoading || isSuccess}
            error={loginError?.message && t('auth.login.defaultError')}
          />
        )}
      </BoxContainer>
    </>
  )
}
