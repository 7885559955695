import React from 'react'

import classes from './BoxContainer.module.scss'

interface BoxContainerProps {
  children: React.ReactNode
  className?: string
}

export const BoxContainer = ({
  children,
  className = '',
}: BoxContainerProps) => (
  <div className='d-flex justify-content-center'>
    <div
      className={`mc-p-8 mc-p-sm-10 mc-bg-container-dark ${classes.container} ${className}`}
    >
      {children}
    </div>
  </div>
)
