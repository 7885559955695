/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconVisibilityOnDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M3.47 11.96a.81.81 0 0 0 0 1.083c.738.794 1.938 1.953 3.43 2.91 1.5.962 3.25 1.693 5.1 1.693s3.6-.731 5.1-1.693c1.492-.957 2.692-2.116 3.43-2.91a.81.81 0 0 0 0-1.083c-.738-.794-1.938-1.953-3.43-2.91-1.5-.962-3.25-1.693-5.1-1.693s-3.6.731-5.1 1.693c-1.492.957-2.692 2.116-3.43 2.91M6.22 7.986C7.832 6.951 9.82 6.094 12 6.094s4.168.857 5.782 1.892c1.616 1.037 2.899 2.28 3.677 3.118l.006.007a2.076 2.076 0 0 1 0 2.781l-.006.007c-.778.838-2.06 2.08-3.677 3.118-1.614 1.035-3.603 1.892-5.782 1.892s-4.168-.857-5.781-1.892c-1.617-1.037-2.9-2.28-3.678-3.118l-.006-.007a2.076 2.076 0 0 1 0-2.781l.006-.007c.778-.838 2.06-2.08 3.678-3.118'
          clipRule='evenodd'
        />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12 10.244a2.256 2.256 0 1 0 0 4.512 2.256 2.256 0 0 0 0-4.512M8.48 12.5a3.52 3.52 0 1 1 7.04 0 3.52 3.52 0 0 1-7.04 0'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconVisibilityOnDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconVisibilityOnDefault
