/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconVisibilityOffDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M20.393 4.563a.625.625 0 0 1-.018.884L4.75 20.448a.625.625 0 1 1-.865-.902l15.626-15a.625.625 0 0 1 .883.017M17.954 9.53a.625.625 0 0 1 .873-.137 17.4 17.4 0 0 1 2.654 2.386 2 2 0 0 1 0 2.69c-2.18 2.409-5.803 4.965-9.484 4.905a8.9 8.9 0 0 1-2.683-.403.625.625 0 1 1 .372-1.194c.748.234 1.528.35 2.311.347h.014c3.15.054 6.457-2.188 8.543-4.494l.001-.002a.75.75 0 0 0 0-1.009v-.001a16 16 0 0 0-2.464-2.214.625.625 0 0 1-.137-.873'
          clipRule='evenodd'
        />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12.009 6.872a10.05 10.05 0 0 1 4.52 1.129.625.625 0 0 1-.575 1.11 8.8 8.8 0 0 0-3.96-.989h-.005c-1.52-.026-3.118.498-4.622 1.345-1.5.845-2.87 1.99-3.921 3.15v.001a.75.75 0 0 0 0 1.01l.003.003a16 16 0 0 0 3.954 3.209.625.625 0 1 1-.623 1.083 17.3 17.3 0 0 1-4.262-3.457 2 2 0 0 1 .002-2.689c1.132-1.248 2.604-2.482 4.233-3.4 1.625-.915 3.443-1.536 5.256-1.505'
          clipRule='evenodd'
        />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M13.698 11.149a2.584 2.584 0 0 0-3.69 3.543.625.625 0 1 1-1.015.73 3.834 3.834 0 0 1 5.477-5.257.625.625 0 1 1-.772.984M15.125 12.496c.345 0 .625.28.625.625a3.75 3.75 0 0 1-3.75 3.75.625.625 0 0 1 0-1.25 2.5 2.5 0 0 0 2.5-2.5c0-.345.28-.625.625-.625'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconVisibilityOffDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconVisibilityOffDefault
