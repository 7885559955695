import { useCallback } from 'react'
import { usePersonaTrack } from '../../tracking'

interface UseAuthEventsProps {
  step: 1 | 2 | 'reset-password'
}

type UserTypes = 'new user' | 'existing user'

export const useAuthEvents = ({ step }: UseAuthEventsProps) => {
  const { track, trackPage, loaded } = usePersonaTrack()

  let page = ''
  if (step === 1) page = 'step one login: insert email or social'
  if (step === 2) page = 'step two login: enter password'
  if (step === 'reset-password') page = 'reset password'

  const trackPageLoadEmailStep = useCallback(() => {
    if (loaded) {
      trackPage('Page Loaded', {
        page,
      })
      track('Persona Sign Viewed', {
        page,
      })
    }
  }, [loaded, page, trackPage, track])

  const trackPageLoadPasswordStep = useCallback(() => {
    if (loaded) {
      trackPage('Page Loaded', {
        page,
        user_type: 'existing user',
      })
      track('Persona Sign Viewed', {
        page,
        user_type: 'existing user',
      })
    }
  }, [loaded, page, trackPage, track])

  const trackSignClick = ({
    cta,
    userType,
  }: {
    cta: string
    userType?: UserTypes
  }) => {
    if (loaded) {
      track('Persona Sign Click', {
        page,
        ...(cta && { cta }),
        ...(userType && { user_type: userType }),
      })
    }
  }

  const trackPageLoadForgotPassword = useCallback(() => {
    if (loaded) {
      trackPage('Page Loaded', {
        page,
        user_type: 'existing user',
      })
      track('Persona Sign Viewed', {
        page,
        user_type: 'existing user',
      })
    }
  }, [loaded, page, track, trackPage])

  return {
    trackPageLoadEmailStep,
    trackPageLoadPasswordStep,
    trackSignClick,
    trackPageLoadForgotPassword,
    page,
  }
}
