import React, { useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import PropTypes from 'prop-types'

import { PROP_TYPE_REDUX_FORM_ELEMENT } from '../Forms/constants'
import InputField from '../InputField'
import { IconVisibilityOffDefault, IconVisibilityOnDefault } from '../Icons'
import Button from '../Button'

import styles from './styles.module.scss'

type PasswordInputProps = FieldRenderProps<string, HTMLElement> & {
  isDarkMode?: boolean
}

const PasswordInput = ({
  append,
  type,
  onTogglePassword,
  isDarkMode = false,
  ...props
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const IconComponent = showPassword
    ? IconVisibilityOnDefault
    : IconVisibilityOffDefault

  const handleVisibilityIconClick = () => {
    onTogglePassword(!showPassword)
    setShowPassword(!showPassword)
  }

  return (
    <InputField
      append={
        <Button
          kind='link'
          className='mc-p-0'
          onClick={handleVisibilityIconClick}
        >
          <IconComponent
            className={
              isDarkMode ? styles.visibilityIconDark : styles.visibilityIcon
            }
          />
        </Button>
      }
      type={showPassword ? 'text' : 'password'}
      {...props}
    />
  )
}

PasswordInput.propTypes = {
  ...PROP_TYPE_REDUX_FORM_ELEMENT,
} as PropTypes.ValidationMap<PasswordInputProps>

export default PasswordInput
