import { GetServerSideProps } from 'next'

import {
  LoginPage,
  paths,
  PERSONA_HIDE_LOGIN_BUTTON_FLAG,
} from '@mc/persona-client'
import { getBucket } from '@mc/next-growthbook'

import { getPersonaAuthServerProps } from '../utils/getPersonaAuthServerProps'

const Login = ({ errorMessage }: { errorMessage?: string }) => (
  <LoginPage errorMessage={errorMessage} />
)

Login.pageSettings = {
  hideNavigation: true,
  title: 'MasterClass On Call',
  footer: { hide: true },
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const hideLoginBucket = await getBucket(ctx, PERSONA_HIDE_LOGIN_BUTTON_FLAG)
  if (hideLoginBucket === 'variant_1') {
    return {
      redirect: {
        destination: paths.root,
        permanent: false,
      },
    }
  }

  const personaProps = await getPersonaAuthServerProps(ctx, true)
  const { query } = ctx
  if (personaProps?.redirect) {
    return personaProps
  }

  return {
    props: {
      ...(query.error && { errorMessage: query.error }),
    },
  }
}

export default Login
