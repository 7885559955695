import React from 'react'

import { CheckoutProvider } from '../../components/CheckoutProvider'

import { LoginSteps } from './LoginSteps'

export const LoginPage = ({ errorMessage }: { errorMessage?: string }) => (
  <CheckoutProvider emailCookie={null}>
    <LoginSteps errorMessage={errorMessage} />
  </CheckoutProvider>
)
