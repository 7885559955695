import React, { createContext, useState } from 'react'

import { useTranslation } from '@mc/i18n'

import {
  getSelectedTierCookie,
  setEmailCookie,
  setSelectedTierCookie,
} from '../../utils/cookies'
import { type Tier } from '../../types/checkout'
import { useAuth0User } from '../../hooks/useAuth0User'
import { useTiers } from '../TierProvider/TierProvider'

interface CheckoutContextType {
  email: string
  setEmail: (email: string) => void
  setSelectedTier: (id: string) => void
  selectedTier: string
  selectedTierObj?: Tier
  tiers: Tier[]
}

const INITIAL_CONTEXT: CheckoutContextType = {
  email: '',
  setEmail: () => {},
  selectedTier: '',
  setSelectedTier: () => {},
  tiers: [],
}

const CheckoutContext = createContext<CheckoutContextType>(INITIAL_CONTEXT)

export const useCheckoutData = () => {
  const ctx = React.useContext(CheckoutContext)
  if (!ctx) {
    throw new Error('useCheckoutData must be used within CheckoutProvider')
  }
  return ctx
}

const sortTiers = (priceA: Tier, priceB: Tier) => {
  if (
    priceA.recurring.interval === 'year' &&
    priceB.recurring.interval === 'month'
  ) {
    return -1
  }
  if (
    priceA.recurring.interval === 'month' &&
    priceB.recurring.interval === 'year'
  ) {
    return 1
  }
  return 0
}

const findYearly = (tier: Tier) => tier.recurring.interval === 'year'

export const CheckoutProvider = ({
  children,
  emailCookie = null,
}: {
  children: React.ReactNode
  emailCookie: string | null
}) => {
  const { t } = useTranslation('@mc/persona')
  const { user } = useAuth0User()
  const { monthlyTier, annualTier } = useTiers()

  // Maps the tiers to include the prices in full, decimals and with currency formats
  const tiersList: Tier[] = [monthlyTier, annualTier]
    .filter((tier): tier is Tier => !!tier)
    .map((tier) => ({
      ...tier,
      internalData: {
        ...tier.internalData,
        ...(!!findYearly(tier) && {
          badge: t('checkout.membership.tiers.bestValue'),
        }),
      },
    }))
    .sort(sortTiers)

  // Email is set to the user email if available, otherwise it's set to the email cookie || ''
  const [email, setEmail] = useState<CheckoutContextType['email']>(
    user?.email || emailCookie || '',
  )

  const defaultSelectedTier =
    tiersList.find((tier) => tier.id === getSelectedTierCookie()) ||
    // Fallback to yearly tier if no tier is selected/valid
    tiersList.find(findYearly)

  const [selectedTier, setSelectedTier] = useState<string>(
    defaultSelectedTier?.id || '',
  )
  const selectedTierObj = tiersList.find((tier) => tier.id === selectedTier)

  const handleSetEmail = (emailAddress: string) => {
    setEmail(emailAddress)
    setEmailCookie(emailAddress)
  }

  const handleSetSelectedTier = (id: string) => {
    setSelectedTierCookie(id)
    setSelectedTier(id)
  }

  return (
    <CheckoutContext.Provider
      value={{
        email,
        setEmail: handleSetEmail,
        selectedTier,
        setSelectedTier: handleSetSelectedTier,
        tiers: tiersList,
        selectedTierObj,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
